import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { renderLoader } from "./lib/renderLoader";
window.addEventListener('DOMContentLoaded', function () {
    var restaurantForm = document.getElementById('add-your-venue');
    if (restaurantForm !== null) {
        var AddYourVenue = lazy(function () { return import('./forms/pages/AddYourVenue'); });
        var element = (React.createElement(Suspense, { fallback: renderLoader() },
            React.createElement(AddYourVenue, null)));
        ReactDOM.render(element, restaurantForm);
    }
    var suggestVenueContainer = document.getElementById('suggest-a-venue');
    if (suggestVenueContainer !== null) {
        var SuggestAVenue = lazy(function () { return import('./forms/pages/SuggestAVenue'); });
        var element = (React.createElement(Suspense, { fallback: renderLoader() },
            React.createElement(SuggestAVenue, null)));
        ReactDOM.render(element, suggestVenueContainer);
    }
    var leaveReviewContainer = document.getElementById('submit-review');
    if (leaveReviewContainer !== null) {
        var ReviewVenue = lazy(function () { return import('./forms/pages/ReviewVenue'); });
        if ((typeof leaveReviewContainer.dataset.venueId !== 'undefined' && leaveReviewContainer.dataset.venueId.length) ||
            (typeof leaveReviewContainer.dataset.placeId !== 'undefined' && leaveReviewContainer.dataset.placeId.length)) {
            var element = (React.createElement(Suspense, { fallback: renderLoader() },
                React.createElement(ReviewVenue, { venueID: leaveReviewContainer.dataset.venueId || null, placeID: leaveReviewContainer.dataset.placeId || null })));
            ReactDOM.render(element, leaveReviewContainer);
        }
        else {
            //TODO: print error?
        }
    }
    var searchBox = document.querySelectorAll('.search-box');
    if (searchBox !== null) {
        var MiniSearch_1 = lazy(function () { return import('./search/pages/MiniSearch'); });
        console.log(searchBox);
        searchBox.forEach(function (DOMNode) {
            var element = (React.createElement(Suspense, { fallback: renderLoader() },
                React.createElement(MiniSearch_1, null)));
            ReactDOM.render(element, DOMNode);
        });
    }
    var reviewsWrapper = document.getElementById('venue-reviews');
    if (reviewsWrapper !== null && typeof reviewsWrapper.dataset.venueId !== 'undefined') {
        var venueID = parseInt(reviewsWrapper.dataset.venueId);
        var Reviews = lazy(function () { return import('./reviews/VenueReviews'); });
        var element = (React.createElement(Suspense, { fallback: renderLoader() },
            React.createElement(Reviews, { venueID: venueID })));
        ReactDOM.render(element, reviewsWrapper);
    }
    var searchPage = document.getElementById('search-page');
    if (searchPage !== null) {
        var SearchPage = lazy(function () { return import('./search/pages/SearchPage'); });
        var element = (React.createElement(Suspense, { fallback: renderLoader() },
            React.createElement(SearchPage, null)));
        ReactDOM.render(element, searchPage);
    }
});
